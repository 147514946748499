import * as React from "react"
import { Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/NewsDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

const EdgeqPressKit10 = () => (
    <Layout>
        <Seo title="EdgeQ | EdgeQ Closes $75M Series-B Investment and Ramps" />

        <section className="News-detail">
            <Container>
                <Row>
                    <div className="detail-head">
                        <Link to="/media/#August" className="back-btn">
                            <FiArrowLeft /> Company Announcements
                        </Link>

                        <h2>
                            EdgeQ Named as a Cool Vendor in the 2023 Gartner® Cool Vendors™ for Communications Service Providers Report
                        </h2>
                    </div>

                    <div className="detail-para">
                        <p className="text-left">
                            <i>
                                EdgeQ also received industry accolades from Mobile World Congress Barcelona and India Mobile
                                Congress in 2023
                            </i>
                        </p>

                        <div className="ajit-social">
                            <ul className="social-icons">
                                <li>
                                    <a
                                        aria-label="Save"
                                        href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <AiFillLinkedin />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        aria-label="Save"
                                        href="https://twitter.com/edgeq_inc?lang=en"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaTwitter />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        aria-label="Save"
                                        href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaYoutube />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <p>
                            <b>SANTA CLARA, CA</b> – November 16, 2023 –{" "}
                            <Link to="/">EdgeQ,</Link> a leader in 5G wireless infrastructure, today announced it
                            has been recognized in the Cool Vendors for Communications Service Providers report by Gartner. EdgeQ
                            is known for its groundbreaking approach to wireless infrastructure by unifying 4G, 5G, and AI onto a
                            single chip that can elastically scale in performance and features via software upgrades. EdgeQ is the
                            only company offering simultaneous 4G and 5G connectivity with integrated AI compute, at one-half the
                            cost, one-third the power, and one-tenth the space of existing chipsets.
                        </p>

                        <p>
                            With generative AI fueling real-time compute, the convergence of connectivity and compute has become
                            essential for enterprise and consumer applications. To accelerate enterprise deployments, EdgeQ
                            uniquely offers a production grade L1 and L2/L3 full software stack, along with its partners, for small and
                            open radio access network (O-RAN) macro cell applications.
                        </p>

                        <p>
                            “We believe that this recognition from Gartner is another validating moment honoring our team’s
                            dedication to revolutionizing wireless infrastructure,” said Vinay Ravuri, CEO and founder of EdgeQ. “We
                            have scaled a mature, proven product into a robust business qualified by manufacturers and enterprises
                            worldwide. Given the stringent requirements of delivering a carrier grade product, this is a phenomenal
                            achievement. The capability to deploy 5G in a simple construct, driven solely by software, at
                            breakthrough unit economics distinguishes us as an innovator and category leader.”
                        </p>

                        <p>
                            According to Gartner, “Innovative players and new technologies in the areas of IoT, autonomous robotics
                            and edge networking are disrupting the CSP market. CSP CIOs must work with the outsiders, startups and
                            entrepreneurs that are offering these technologies to improve agility, performance and efficiency.”
                            Customers design with EdgeQ to drive transformative 5G use cases, such as integrated access and
                            backhaul for enterprises, multi-tenant neutral hosting, and non-terrestrial 5G network communications.
                        </p>

                        <p>
                            This year, EdgeQ was also honored with the Global Mobile (GLOMO) CTO Choice Award: Outstanding
                            Mobile Technology at Mobile World Congress Barcelona and the India Mobile Congress Best Global
                            Startup Award, marking a momentous 2023. EdgeQ received these honors due to its unique ability to
                            address the fast-growing, multi-billion-dollar 5G infrastructure market with a single chip that converges
                            connectivity and compute at the edge.
                        </p>

                        <p>
                            To learn more about how EdgeQ is pioneering the 5G infrastructure
                            market, please visit <Link to="/">www.edgeq.io</Link>.
                        </p>

                        <p>
                            (1) Gartner “Cool Vendors for Communications Service Providers” by Mike Edholm, Kosei
                            Takiishi, Amol Nerlekar, Mounish Rai, Peter Liu, October 9, 2023.</p>

                        <p>
                            GARTNER is a registered trademark and service mark of Gartner, Inc. and/or its affiliates in the U.S. and
                            internationally, and COOL VENDORS is a registered trademark of Gartner, Inc. and/or its affiliates and are
                            used herein with permission. All rights reserved.
                        </p>

                        <b>Gartner Disclaimer:</b>
                        <br />
                        <br />
                        <p>
                            Gartner does not endorse any vendor, product or service depicted in our research publications, and does
                            not advise technology users to select only those vendors with the highest ratings or other designation.
                            Gartner research publications consist of the opinions of Gartner's research organization and should not
                            be construed as statements of fact. Gartner disclaims all warranties, expressed or implied, with respect
                            to this research, including any warranties of merchantability or fitness for a particular purpose.
                        </p>

                        <b>About EdgeQ</b>
                        <br />
                        <br />
                        <p>
                            EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA,
                            with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and
                            Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and
                            programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link>
                        </p>
                        {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
                    </div>
                </Row>
            </Container>
        </section>
    </Layout>
)

export default EdgeqPressKit10
